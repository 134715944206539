import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { FaTimesCircle  } from 'react-icons/fa';
import './NewsletterForm.css';
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../api/googleApi";
import { addDoc, collection } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";


const NewsletterForm = ({isshow}) => {
  const [show, setShow] = useState(isshow); // Always show the form on load
  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);



  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    address: "",
    email: "",
    content: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    const imageRef = 'banner/contact-banner.jpg'; // Đường dẫn của ảnh trong Firebase Storage

    const fetchImageUrl = async () => {
      try {
        const storageRef = ref(storage, imageRef); // Tạo reference tới ảnh
        const url = await getDownloadURL(storageRef); // Lấy URL của ảnh từ Firebase Storage
        setImageUrl(url); // Lưu URL vào state
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const { fullName, phoneNumber, address, email, content } = formData;
  
    if (!fullName.trim() || !phoneNumber.trim() || !address.trim() || !email.trim() || !content.trim()) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }
  
    try {
      const docRef = await addDoc(collection(db, "contacts"), formData);
      console.log("Document written with ID: ", docRef.id);

      // Hiển thị modal thành công
      setShowModal(true);

      setFormData({ fullName: "", phoneNumber: "", address: "", email: "", content: "" });
      setError(null);
    } catch (e) {
      console.error("Error adding document: ", e);
      setError("Failed to submit form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      {/* React Bootstrap Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header style={{textAlign:"center"}} >
          <Modal.Title className="mx-auto" style={{ color: '#3c9c3d', textAlign: 'center' }}>{t("REGISTER FOR QUOTE")}</Modal.Title>
          <FaTimesCircle  onClick={handleClose} className="close-icon" />
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="fullName">
                          <Form.Control
                            type="text"
                            placeholder={t("Full name")}
                            value={formData.fullName}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter your full name")}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="email">
                          <Form.Control
                            type="email"
                            placeholder={t("Email")}
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter a valid email address")}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="phoneNumber">
                          <Form.Control
                            type="text"
                            placeholder={t("Phone number")}
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter your phone number")}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="address">
                          <Form.Control
                            type="text"
                            placeholder={t("Address")}
                            value={formData.address}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter your address")}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="content" className="input-contact">
                      <Form.Control
                        as="textarea"
                        placeholder={t("Content")}
                        value={formData.content}
                        onChange={handleChange}
                        isInvalid={!!error}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please enter your message")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {error && <p className="text-danger">{error}</p>}
                    <Button type="submit" className="w-100 " disabled={loading} style={{backgroundColor:'#3c9c3d'}}>
                      {loading ? t("Sending...") : t("Send")}
                    </Button>
                   
          </Form>
        </Modal.Body>
      </Modal>
       {/* Modal */}
       <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Success")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Your message has been sent successfully! We will contact you as soon as possible.")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewsletterForm;
