import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";
import { Breadcrumb, Col, ListGroup, Row, Tab } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import ProductGrouplist from './component/productGroupList/productGroupList';
import './products.css';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../api/googleApi';

function Products() {
  const [product, setProduct] = useState("Products");  // Tiêu đề mặc định
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  const [imageUrl, setImageUrl] = useState(null);

  // Lấy URL hình ảnh từ Firebase
  useEffect(() => {
    const imageRef = 'banner/products-banner.jpg'; // Đường dẫn của ảnh trong Firebase Storage

    const fetchImageUrl = async () => {
      try {
        const storageRef = ref(storage, imageRef); // Tạo reference tới ảnh
        const url = await getDownloadURL(storageRef); // Lấy URL của ảnh từ Firebase Storage
        setImageUrl(url); // Lưu URL vào state
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, []);

  // Cập nhật tiêu đề dựa trên `selectedItem`
  useEffect(() => {
    switch (selectedItem) {
      case '/Products/1':
        setProduct(('Paper Cup'));
        break;
      case '/Products/2':
        setProduct(('Plastic Cup'));
        break;
      case '/Products/3':
        setProduct(('Food Bowl'));
        break;
      case '/Products/4':
        setProduct(('Food Container'));
        break;
      case '/Products/5':
        setProduct(('Aluminum Foil Products'));
        break;
      case '/Products/6':
        setProduct(('Tissue'));
        break;
      case '/Products/7':
        setProduct(('Paper bag'));
        break;
      case '/Products/8':
        setProduct(('Bagasse Products'));
        break;
      case '/Products/9':
        setProduct(('Accompanying Products'));
        break;
      case '/Products/10':
        setProduct(('Biodegradable Products'));
        break;
      case '/Products/11':
        setProduct('Watter base');
        break;
      default:
        setProduct("Products");
    }
  }, [selectedItem, t]); // Thay đổi khi selectedItem hoặc t (i18n) thay đổi

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey); // Cập nhật selectedItem khi người dùng chọn mục
  };

  const hasCategoryRoute = location.pathname.includes('/Products/');

  return (
    <div className='main-product '>
      <Breadcrumb className='breadcrumb-main'>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Products">{t('Products')}</Breadcrumb.Item>
        {selectedItem && (
          <Breadcrumb.Item active>{product}</Breadcrumb.Item>
        )}
      </Breadcrumb>
      
      {/* Main content */}
      <Row>
        <Col md={4}>
          <div className="menu-product">
            <Tab.Container id="list-group-tabs-example" onSelect={handleSelect}>
              <Row>
                <ListGroup>
                  <ListGroup.Item className='title-menu'>{t('PRODUCT CATALOG')}</ListGroup.Item>
                  <ListGroup.Item action href='/Products/1' active={selectedItem === '/Products/1'}>
                    {t('Paper Cup')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/2' active={selectedItem === '/Products/2'}>
                    {t('Plastic Cup')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/3' active={selectedItem === '/Products/3'}>
                    {t('Food Bowl')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/4' active={selectedItem === '/Products/4'}>
                    {t('Food Container')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/5' active={selectedItem === '/Products/5'}>
                    {t('Aluminum Foil Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/6' active={selectedItem === '/Products/6'}>
                    {t('Tissue')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/7' active={selectedItem === '/Products/7'}>
                    {t('Paper bag')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/8' active={selectedItem === '/Products/8'}>
                    {t('Bagasse Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/9' active={selectedItem === '/Products/9'}>
                    {t('Accompanying Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/10' active={selectedItem === '/Products/10'}>
                    {t('Biodegradable Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/11' active={selectedItem === '/Products/11'}>
                    Watter base
                  </ListGroup.Item>
                </ListGroup>
              </Row>
            </Tab.Container>
          </div>
        </Col>
        <Col md={8}>
        <h1 className='product-title' style={{paddingBottom:'30px',paddingTop:"10px", textAlign:'center', color:'#F59F05'}}><strong>{t(product).toUpperCase()}</strong></h1>
          {!hasCategoryRoute ? (
            <div className="default-content">
              <ProductGrouplist />
            </div>
          ) : (
            <div className="category-content">
              <Outlet />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Products;
