import React, { useEffect } from "react";
import "./AnnualMeeting.css"; // Import your CSS file
import { FaCalendarWeek } from "react-icons/fa";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const AnnualMeeting = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main">
      <div className="maxwidth">
        <div className="content-main w-clear">
          <div className="row">
            <div className="col-md-12">
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/news" >
        {t('News')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item active >
        {t('Annual meeting of Khiem Hoa and Nice companies')} 
        </Breadcrumb.Item>
      </Breadcrumb>
              <div className="title-main">
                <h2><strong></strong>{t('ANNUAL MEETING OF KHIEM HOA AND NICE COMPANY')}</h2>
              </div>
              <div className="time-main">
                <i className="fas fa-calendar-week"></i>
                <span><FaCalendarWeek/> {t('Posted date')}: 23/1/2024 07:08 PM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" id="toc-content">
                  <h1 className="text-justify" >
                  {t('On January 19, 2019, the annual meeting of Khiem Hoa Co., Ltd. and Nice took place smoothly in the multi-purpose conference room. Mr. Chu, General Director of the company, gave a meaningful speech to evaluate the dedication and efforts of the staff. He expressed his sincere gratitude for the solidarity and continuous efforts of the entire staff, which helped Khiem Hoa Company achieve remarkable achievements in 2018')}.
                  </h1>

                  <p className="text-center">
                    <img
                      alt="Nice International LLC"
                      height="424"
                      src="https://nicecupvn.com//upload/elfinder/company-profile-02.jpg"
                      width="600"
                    />
                  </p>

                  <p className="text-justify"style={{ textIndent: '2em' }}>
                  {t("Mr. Chu also summarized the success of the company's departments, which have completed their set goals in the past year. At the same time, he announced a detailed plan for 2019, pledging that the company will continuously perfect and improve the production quality of products such as paper cups, paper bowls, cartons, paper bags, and paper boxes, tissue paper, and other accompanying products")}.
                  </p>

                  <p className="text-center">
                    <img
                      alt="Nice International LLC"
                      height="450"
                      src="https://nicecupvn.com//upload/elfinder/hop.jpg"
                      width="600"
                    />
                  </p>

                  <p className="text-justify"style={{ textIndent: '2em' }}>
                  {t("In the coming year, the Company will continue to focus on improving the quality of products such as plastic cups, plastic containers, plastic lids, aluminum items, and PLA degradable products. \"Quality first\" and \"Customer satisfaction is our honor\" remain at the heart of the company's goals. Mr. Chu pledged that the company will constantly strive to develop Khiem Hoa stronger and stronger")}.
                  </p>

                  <p className="text-justify"style={{ textIndent: '2em' }}>
                  {t("At the meeting, the company emphasized and recognized the significant efforts of the staff, emphasizing the great significance of this consensus and effort for the company's sustainable development in the future")}.
                  </p>

                  <p className="text-center">
                    <img
                      alt="Nice International LLC"
                      height="800"
                      src="https://nicecupvn.com//upload/elfinder/hopp.jpg"
                      width="600"
                    />
                  </p>
                </div>
                </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualMeeting;
