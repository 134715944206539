import React, { useEffect } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FaCalendarWeek } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";

const PaperProductionProcess = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main">
      <Container className="maxwidth">
      <div >
        <div className="content-main w-clear">
          <Row>
            <Col md={12}>
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Technology" >
        {t('Technology')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item active >{t('Paper product manufacturing process')}</Breadcrumb.Item>
      </Breadcrumb>
              <h1 className="title-main"><strong>{t('PAPER PRODUCT MANUFACTURING PROCESS')}</strong></h1>
              <div className="time-main">
                <FaCalendarWeek />
                <span> {t('Posted date')}: 23/01/2024 07:08 PM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" id="toc-content">
                  <p style={{ fontSize: '16px', fontFamily: 'Arial,Helvetica,sans-serif' }}>
                  {t('The company often uses modern machinery to optimize the paper production process, from the production of paper cups and packaging. This technology helps increase efficiency and reduce waste')}.
                  </p>
                  <div>
                    <img
                      alt="Công Ty TNHH Quốc Tế Nice"
                      height="605"
                      src="https://nicecupvn.com//upload/elfinder/z5221695497291_d11b2c14ea744dc372a2a0b20045729a.jpg"
                      width="1080"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      alt="Công Ty TNHH Quốc Tế Nice"
                      height="618"
                      src="https://nicecupvn.com//upload/elfinder/z5221696559952_2f2a668ca4ba5323d267f91fef1ed52f.jpg"
                      width="1090"
                    />
                  </div>
                </div>
                
                <div className="pagination-home"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
    </div>
    
  );
};

export default PaperProductionProcess;
