// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import viTranslations from './locales/vi/translation.json';
import thaTranslations from './locales/tha/translation.json';

// Lấy ngôn ngữ từ localStorage hoặc sử dụng ngôn ngữ mặc định
const language = localStorage.getItem('language') || 'vi';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      vi: { translation: viTranslations },
      tha: {translation: thaTranslations}
    },
    lng: language, // Sử dụng ngôn ngữ từ localStorage
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
