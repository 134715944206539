import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import "./Statistics.css";
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const Statistics = () => {
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();

  const handleScroll = () => {
    const section = document.getElementById("statistics");
    const rect = section.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

    if (isVisible) {
      setStartAnimation(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section id="statistics" className="statistics-section">
      <h2><strong>{t('STATISTICS')}</strong></h2>
      <div className="statistics-grid">
        <div className="statistic-item">
          <div className="icon">
          <img src="https://nicecupvn.com/upload/news/tk2-1237.png" alt="Nice International LLC" className="ads-image" />
          </div>
          <div className="statistic-value">
            {startAnimation && <CountUp end={1000000} duration={2} />} +
          </div>
          <p>{t('Loyal customers trust')}</p>
        </div>
        <div className="statistic-item">
          <div className="icon">
          <img src="https://nicecupvn.com/upload/news/tk3-2168.png" alt="Nice International LLC" className="ads-image" />
          </div>
          <div className="statistic-value">
            {startAnimation && <CountUp end={1000000} duration={2} />} +
          </div>
          <p>{t('Consumer use')}</p>
        </div>
        <div className="statistic-item">
          <div className="icon">
          <img src="https://nicecupvn.com/upload/news/tk4-6302.png" alt="Nice International LLC" className="ads-image" />
          </div>
          <div className="statistic-value">
            {startAnimation && <CountUp end={100} duration={2} />} +
          </div>
          <p>{t('Reduce 100 tons of plastic waste')}</p>
        </div>
        <div className="statistic-item">
          <div className="icon">
          <img src="https://nicecupvn.com/upload/news/tk1-1710.png" alt="Nice International LLC" className="ads-image" />
          </div>
          <div className="statistic-value">
            {startAnimation && <CountUp end={1000000} duration={2} />} +
          </div>
          <p>{t('Products consumed each year')}</p>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
