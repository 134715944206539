import React, { useEffect } from 'react';
import './WhyChooseUs.css'; 
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const WhyChooseUs = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();
  return (
    <section id="visao">
      <div className="maxwidth">
        <div className="title-why aos-init aos-animate" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="1000">
          <h2><strong>{t('WHY CHOOSE US')}?</strong></h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="why-item-all">
              <div className="why-item aos-init aos-animate" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="1000">
                <div className="why-item_img">
                  <img onError={(e) => e.target.src='https://nicecupvn.com/thumbs/140x140x2/assets/images/noimage.png'} src="https://nicecupvn.com/upload/news/vs2-4029.png" alt="COMPETITIVE PRICES" />
                </div>
                <div className="why-item_text">
                  <h3>{t('COMPETITIVE PRICES')}</h3>
                  <p>{t('Committed to providing products at the most competitive prices on the market. Flexible pricing strategy to ensure competitiveness and savings for customers.')}</p>
                </div>
              </div>
              <div className="why-item aos-init" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="1000">
                <div className="why-item_img">
                  <img onError={(e) => e.target.src='https://nicecupvn.com/thumbs/140x140x2/assets/images/noimage.png'} src="https://nicecupvn.com/upload/news/vs3-3593.png" alt="FAST SHIPPING" />
                </div>
                <div className="why-item_text">
                  <h3>{t('FAST SHIPPING')}</h3>
                  <p>{t('Modern transportation system, ensuring goods are delivered quickly and safely. Reputable shipping partners, helping to reduce shipping time and costs.')}</p>
                </div>
              </div>
              <div className="why-item aos-init" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="1000">
                <div className="why-item_img">
                  <img onError={(e) => e.target.src='https://nicecupvn.com/thumbs/140x140x2/assets/images/noimage.png'} src="https://nicecupvn.com/upload/news/vs4-3660.png" alt="CUSTOMER CARE TEAM" />
                </div>
                <div className="why-item_text">
                  <h3>{t('CUSTOMER SERVICE TEAM')}</h3>
                  <p>{t('Professional and attentive customer care team, always willing to support and solve all customer problems. Quick and flexible response to meet all customer needs.')}</p>
                </div>
              </div>
              <div className="why-item aos-init" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="1000">
                <div className="why-item_img">
                  <img onError={(e) => e.target.src='https://nicecupvn.com/thumbs/140x140x2/assets/images/noimage.png'} src="https://nicecupvn.com/upload/news/vs1-9562.png" alt="HIGH QUALITY PRODUCTS" />
                </div>
                <div className="why-item_text">
                  <h3>{t('HIGH QUALITY PRODUCTS')}</h3>
                  <p>{t('Products are manufactured from high quality raw materials, ensuring safety and standards. Strict production processes and tight quality control to ensure products meet or exceed customer expectations.')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center mt-4 aos-init aos-animate" data-aos="zoom-in" data-aos-easing="ease-in-out" data-aos-duration="1000">
              <img onError={(e) => e.target.src='https://nicecupvn.com/thumbs/219x73x2/assets/images/noimage.png'} src="https://nicecupvn.com/upload/photo/ly-4459.jpg" alt="Nice International LLC" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
