import React, { useEffect } from 'react';
import './salesPolicy.css'
import { Container, Row, Col, Breadcrumb  } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { FaCalendarWeek } from 'react-icons/fa';

const SalesPolicy = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
    return (
      <div id="main" className="wrap-main" >
        <Container className="maxwidth">
        <div >
          <Row>
            <Col md={12}>
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item  active>
        {t('Sales Policy')} 
        </Breadcrumb.Item>
      </Breadcrumb>
              <div className="title-main">
                <h1><strong>{t("SALES POLICY")}</strong></h1>
              </div>
              <div className="time-main">
                
                <span><FaCalendarWeek/> {t("Posted date")}: 05/22/2022 08:27 AM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" id="toc-content">
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>1. {t("Order")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("The manner and means by which customers can place orders, including on the company's website, by telephone, by email, or through an agent.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("Please provide complete and accurate information about the product, quantity, and contact information to ensure accurate order confirmation.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>2. {t("Order confirmation")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("Regulations on time and method of order confirmation after customer places order.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("The order confirmation process may include resending a confirmation email or calling to confirm order information.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>3. {t("Shipping and delivery")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("Information about estimated shipping costs and different delivery options.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Estimated delivery time and product return policy in case of damaged product or incorrect delivery.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>4. {t("Cancel order")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Regulations on order cancellation, including order cancellation period and related costs.")}
                  </p>
                </div>
                
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      </div>
        
    );
};

export default SalesPolicy;