// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCymBfZoHe0GcNtzwpdLb-f4qeKTbk8hJ8",
  authDomain: "new-nice-web.firebaseapp.com",
  projectId: "new-nice-web",
  storageBucket: "new-nice-web.appspot.com",
  messagingSenderId: "61844873664",
  appId: "1:61844873664:web:dce17ffec8f55ce16e6825",
  measurementId: "G-21HV1JDR4D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, app as default };
