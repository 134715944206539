import { db } from "./googleApi";
import { collection, getDocs, query, limit, startAfter, orderBy, where, doc, getDoc } from "firebase/firestore";

const productCollectionRef = collection(db, "products");

class ProductData {
    getProductsWithPagination = async (pageSize, startAfterDoc) => {
        let productQuery = query(
            productCollectionRef,
            orderBy("id"), // Trường chính để phân trang
            limit(pageSize)
        );

        if (startAfterDoc) {
            productQuery = query(
                productCollectionRef,
                orderBy("id"),
                startAfter(startAfterDoc),
                limit(pageSize)
            );
        }

        return getDocs(productQuery);
    };

    getAllProduct = () => {
        return getDocs(productCollectionRef);
    };

    // Hàm lấy sản phẩm theo ID
    getProduct = async (id) => {
        try {
            const docRef = doc(productCollectionRef, id); // Tạo DocumentReference
            const docSnap = await getDoc(docRef); // Lấy tài liệu

            if (docSnap.exists()) {
                return docSnap.data(); // Trả về dữ liệu của tài liệu
            } else {
                console.log('No such document!');
                return null; // Nếu tài liệu không tồn tại
            }
        } catch (error) {
            console.error('Error getting document:', error);
            return null; // Trả về null nếu có lỗi
        }
    };
    // Hàm lấy sản phẩm theo Category ID
    getCategory = async (categoryId) => {
        const categoryQuery = query(
            productCollectionRef,
            where("category.id", "==", categoryId) // Lọc theo category id
        );

        return getDocs(categoryQuery);
    };

    getCategoryWithPagination = async (categoryId, pageSize, startAfterDoc) => {
        let productQuery = query(
            productCollectionRef,
            where("category.id", "==", categoryId), // Lọc theo category
            orderBy("id"), // Sắp xếp theo ID
            limit(pageSize)
        );

        if (startAfterDoc) {
            productQuery = query(
                productCollectionRef,
                where("category.id", "==", categoryId),
                orderBy("id"),
                startAfter(startAfterDoc), // Bắt đầu sau document cuối cùng của trang trước
                limit(pageSize)
            );
        }

        return getDocs(productQuery);
    };

}
    
export default new ProductData();

