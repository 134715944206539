// /gtag.js
export const gtag_report_conversion = (url) => {
    const callback = () => {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16699382681/FNVKCJnxjtIZEJm38Zo-',
      'event_callback': callback
    });
  };
  