import React, { useEffect } from 'react';
import { Container, Row, Col, Image,  Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import { FaCalendarWeek } from 'react-icons/fa';
function EstablishedABranch() {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main">
      <Container className="maxwidth">
        <Row className="content-main w-clear">
          <Col md={12}>
          <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/news" >
        {t('News')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/news" active>
        {t('Khiem Hoa Food Packaging Co., Ltd. Dongguan City established a branch in Vietnam')} 
        </Breadcrumb.Item>
      </Breadcrumb> 
            <div className="title-main">
              <h1><strong>{t("KHIEM HOA FOOD PACKAGING CO., LTD. DONGGUAN CITY ESTABLISHED A BRANCH IN VIETNAM")}</strong></h1>
            </div>
            <div className="time-main">
              <i className="fas fa-calendar-week"></i>
              <span><FaCalendarWeek/> {t("Posted date")}: 01/23/2024 07:08 PM</span>
            </div>

            <div className="content-detail">
              <p style={{ textAlign: 'justify' }}>
              {t("In December 2018, Dong Quan Co., Ltd. officially expanded its operations by establishing a branch in Vietnam. The important thing is not only the slogan \"Khiem Hoa creates intellectual products, distributes global goods\" that we convey. This is not just a slogan or a dream, but a real mark, the creativity and dedication of each member at Khiem Hoa")}.
              </p>

              <div style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/vietnam-branch-established-01.jpg" width={600} height={450} alt="Nice International Company Limited" />
              </div>
              
              <div style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/vietnam-branch-established-04.jpg" width={600} height={450} alt="Nice International Company Limited" />
              </div>

              <p style={{ textAlign: 'justify' }}>
              {t("Dongguan Branch is not only a new beginning, but also a new journey, a new energy, and a new mission. We set a lofty goal of manufacturing and providing high-quality products to serve customers around the world")}.
              </p>

              <div style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/vietnam-branch-established-02.jpg" width={600} height={450} alt="Nice International Company Limited" />
              </div>

              <div style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/vietnam-branch-established-03.jpg" width={600} height={450} alt="Nice International Company Limited" />
              </div>

              <p style={{ textAlign: 'justify' }}>
              {t("Wherever you are, as long as you need products such as paper cups, paper bowls, paper boxes, paper bags, paper towels, plastic cups, plastic boxes, plastic lids, aluminum utensils, and biodegradable PLA products, we are ready to serve you. Contact us to experience the most creative and dedicated customer service")}.
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EstablishedABranch;
