import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import './navbar.css';
import logo from '../../images/logo.png';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';



const CustomNavbar = () => {
  const { t } = useTranslation();
  const [showIntroduce, setShowIntroduce] = useState(false);
  const [showProduct, setShowProduct] = useState(false);


  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <div className="navbar-container">
      <Navbar expand="lg"  fixed="top">
        <Container>
          <Navbar.Brand href="/" className="navbar-brand">
            <img src={logo} alt="Company Logo" style={{ height: '60px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" className="nav-link">{t('home')}</Nav.Link>
             <Dropdown as={ButtonGroup}
                title={t('introduce')}
                id="nav-dropdown-introduce"
                className="nav-dropdown"
                onMouseEnter={() => setShowIntroduce(true)}
                onMouseLeave={() => setShowIntroduce(false)}
                show={showIntroduce}
              >
                 <div className="d-flex justify-content-start align-left">
      <Button className="button-introduce" href='/Introduce' style={{paddingTop:'7px'}}>{t('introduce')}</Button>
    </div>
                <Dropdown.Menu>
                <Dropdown.Item href="/companyintroduction">{t('Company introduction')}</Dropdown.Item>
                <Dropdown.Item href="/certificate">{t('Certificate')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as={ButtonGroup}
                title={t('product')}
                id="nav-dropdown-product"
                className="nav-dropdown"
                onMouseEnter={() => setShowProduct(true)}
                onMouseLeave={() => setShowProduct(false)}
                show={showProduct}
              >
                 <div className="d-flex justify-content-start align-left">
      <Button className="button-introduce" href='/Products' style={{paddingTop:'7px'}} >{t('product')}</Button>
    </div>
                <Dropdown.Menu>
                <Dropdown.Item href="/Products/1">{t('Paper Cup')}</Dropdown.Item>
                <Dropdown.Item href="/Products/2">{t('Plastic Cup')}</Dropdown.Item>
                <Dropdown.Item href="/Products/3">{t('Food Bowl')}</Dropdown.Item>
                <Dropdown.Item href="/Products/4">{t('Food Container')}</Dropdown.Item>
                <Dropdown.Item href="/Products/5">{t('Aluminum Foil Products')}</Dropdown.Item>
                <Dropdown.Item href="/Products/6">{t('Tissue')}</Dropdown.Item>
                <Dropdown.Item href="/Products/7">{t('Paper bag')}</Dropdown.Item>
                <Dropdown.Item href="/Products/8">{t('Bagasse Products')}</Dropdown.Item>
                <Dropdown.Item href="/Products/9">{t('Accompanying Products')}</Dropdown.Item>
                <Dropdown.Item href="/Products/10">{t('Biodegradable Products')}</Dropdown.Item>
                <Dropdown.Item href="/Products/10">{t('Water Base')}</Dropdown.Item>
                
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link href="/service" className="nav-link">{t('service')}</Nav.Link>
              <Nav.Link href="/technology" className="nav-link">{t('technology')}</Nav.Link>
              <Nav.Link href="/news" className="nav-link">{t('news')}</Nav.Link>
              <Nav.Link href="/contact" className="nav-link">{t('contact')}</Nav.Link>
              <div className="d-flex ">
            <div className="search-box">
              <input type="text" placeholder={t('search')} />
              <FaSearch className="search-icon"/>
            </div>
            {/* <NavDropdown title={t('Language')} id="language-dropdown" className="language-dropdown">
              <NavDropdown.Item onClick={() => handleLanguageChange('en')}>English</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleLanguageChange('vi')}>Tiếng Việt</NavDropdown.Item> */}
              {/* <NavDropdown.Item onClick={() => handleLanguageChange('tha')}>Tiếng Thái Lan</NavDropdown.Item> */}
            {/* </NavDropdown> */}
            <div style={{ display: 'flex', alignItems: 'center' }} >
            <div onClick={() => handleLanguageChange('vi')}><img src='/images/homepage/vn.png' style={{width:'60px' , height:'45px', padding:'5px'}} alt='vietnam flag'></img></div>
            <div onClick={() => handleLanguageChange('en')}><img src='/images/homepage/uk.png' style={{width:'60px' , height:'45px', padding:'5px'}} alt='englang flag'></img></div>
            </div>
            
            
          </div>
            </Nav>
          </Navbar.Collapse>
          
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
