import React, { useEffect } from 'react';
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import './services.css';
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";



const Services = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
    const services = [
        {
          href: '/service/design-and-printing-support',
          title: 'Hỗ trợ thiết kế và in ấn',
          titleen:"Design and printing support",
          imgSrc: 'https://nicecupvn.com/thumbs/530x560x1/upload/news/mau-sac-trong-thiet-ke-1-1995.jpg',
          altText: 'Hỗ trợ thiết kế và in ấn',
          description: 'Dịch vụ in ấn và thiết kế của công ty sản có thể bao gồm các công đoạn và dịch vụ sau:',
        },
        {
          href: 'service/purchase-payment-method',
          title: 'Phương thức mua hàng và thanh toán',
          titleen:'Purchase and payment methods',
          imgSrc: 'https://nicecupvn.com/thumbs/530x560x1/upload/news/6-hinh-thuc-thanh-toan-truc-tuyen-o-viet-nam3-4030.jpg',
          altText: 'Phương thức mua hàng và thanh toán',
          description: 'Hình thức đặt hàng và thanh toán:',
        },
      ];
    
      return (
        <div id="main" className="wrap-main">
          <Container className="maxwidth">
         
         <div >
           
           <div className="content-main w-clear">
           <Breadcrumb>
       <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
       <Breadcrumb.Item href="/Service" active>
       {t('Service')} 
       </Breadcrumb.Item>
     </Breadcrumb>
             <h1 className="title-main"><strong>{t('SERVICE')}</strong></h1>
             <Row className="row-10">
               {services.map((service, index) => (
                 <Col key={index} md={3} sm={6} xs={6} className="padding-10">
                   <Card className="newshome-normal text-decoration-none w-clear" >
                     <a href={service.href} title={service.title}>
                       <Card.Img
                         className="pic-newshome-normal scale-img"
                         src={service.imgSrc}
                         alt={service.altText}
                         onError={(e) => (e.target.src = 'https://nicecupvn.com/thumbs/530x560x2/assets/images/noimage.png')}
                       />
                       <Card.Body style={{color:'#ffffff', backgroundColor:'#4CAF50', textAlign:'center'}} >
                         <Card.Title className="name-newshome">{(currentLanguage==="en")?service.titleen:service.title}</Card.Title>
                         
                       </Card.Body>
                     </a>
                   </Card>
                 </Col>
               ))}
             </Row>
           </div>
         </div>
       </Container>
        </div>
        
      );
    };




export default Services;