import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GrMail } from "react-icons/gr";
import './MailButton.css'
import { gtag_report_conversion } from '../gtag';

const MailButton = () => {
  return (
    <a
      className="btn-mail btn-frame text-decoration-none hidden-xs"
      href="mailto:monica.vn@qhcup.com"
      onClick={() => gtag_report_conversion('mailto:monica.vn@qhcup.com')}
    >
      <div className="animated infinite zoomIn kenit-alo-circle" style={{border: '2px solid #65b741'}}></div>
      <div className="animated infinite pulse kenit-alo-circle-fill" ></div>
      <div style={{ width:'50px',padding:'5px' , height:'50px'}}>
      <GrMail style={{width:'35px', height:'35px',margin:"7px" , color:'#fff' }} src="https://nicecupvn.com/assets/images/zl.png" alt="Zalo" />
      </div>
      
    </a>
  );
};

export default MailButton;
