import React, { useEffect, useState } from 'react';
import { Card, Col, Pagination, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProductData from '../../../api/productApi';
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from '../../../api/googleApi';
import i18n from "../../../i18n";
import './productGroupList.css' ;

const productsPerPage = 15; // Số sản phẩm mỗi trang

function ProductGrouplist() {
    const [productList, setProductList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null); // Lưu tài liệu cuối cùng của trang hiện tại
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const currentLanguage = i18n.language;
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
      }, []);
    
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                // Lấy sản phẩm cho trang hiện tại
                const querySnapshot = await ProductData.getProductsWithPagination(productsPerPage, lastDoc);
                const tempProductArray = [];

                querySnapshot.forEach((doc) => {
                    tempProductArray.push({ ...doc.data(), id: doc.id });
                });

                // Cập nhật lastDoc cho trang tiếp theo
                const newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                setLastDoc(newLastDoc);

                // Lấy URL hình ảnh từ Firebase Storage
                const productListWithImages = await Promise.all(
                    tempProductArray.map(async (product) => {
                        const imageRef = ref(storage, product.image);
                        const imageUrl = await getDownloadURL(imageRef).catch((error) => {
                            console.error('Failed to get image URL:', error);
                            return ''; // Trả về chuỗi rỗng nếu có lỗi
                        });
                        return { ...product, imageUrl };
                    })
                );

                setProductList(productListWithImages);

                // Tính toán tổng số trang dựa trên tổng số sản phẩm
                const totalDocsSnapshot = await ProductData.getAllProduct();
                setTotalPages(Math.ceil(totalDocsSnapshot.size / productsPerPage));

            } catch (error) {
                console.log('Failed to fetch product list', error);
            }
        };
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchProducts();
    }, [currentPage]);

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);

        // Cập nhật lastDoc dựa trên trang hiện tại
        if (pageNumber === 1) {
            // Nếu trang đầu tiên, không cần lastDoc
            setLastDoc(null);
        } else {
            // Tính toán lastDoc cho trang tiếp theo
            const querySnapshot = await ProductData.getProductsWithPagination(productsPerPage * (pageNumber - 1), null);
            const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastDoc(lastVisibleDoc);
        }
    };

    const handleCardClick = (category_id, productId) => {
        navigate(`/Products/${category_id}/${productId}`);
    };
    function capitalizeFirstLetter(str) {
        if (!str) return str;
  return str
    .split(' ') // Tách chuỗi thành mảng các từ
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Viết hoa chữ cái đầu và chuyển phần còn lại thành chữ thường
    .join(' '); // Nối các từ lại thành chuỗi
      }

      
    document.title= (currentLanguage === "en" )? "Products":"Sản phẩm";
    return (
        <div>
            <Row md={8}>
                {productList.map((product) => (
                    <Col key={product.id} md={4}>
                <div
                key={product.id}
                className="aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
              >
                         <div className='product-card' >
                         <Card style={{ marginBottom: '20px' }} onClick={() => handleCardClick(product.category.id, product.id)}>
                            <Card.Img variant="top" src={product.imageUrl} alt={product.name} />
                            <Card.Body style={{ textAlign: 'center' }}>
                                <Card.Title style={{ fontSize: '20px', padding: '0', color:"#F59F05" }}><strong>{capitalizeFirstLetter((currentLanguage === "en" )? product.name:product.namevn)}</strong></Card.Title>
                                <Card.Text style={{color:'red',fontSize:'15px' }}>{(currentLanguage === "en" )? product.Price: "Liên Hệ"}</Card.Text>
                            </Card.Body>
                        </Card>
                         </div>
                         </div>
                    </Col>
                ))}
            </Row>

            <Pagination className="justify-content-center mt-3">
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {[...Array(totalPages).keys()].map((page) => (
                    <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {page + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
    );
}

export default ProductGrouplist;

