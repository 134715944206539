import React, { useEffect } from "react";
import "./Introduce.css";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";

const Introduce = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
document.title= (currentLanguage === "en" )? "Introduce":"Giới thiệu";

  return (
    <div id="main" className="wrap-main">
      <div className="maxwidth">
     
        <div className="content-main w-clear">
        <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Introduce" active>
        {t('Introduce')} 
        </Breadcrumb.Item>
      </Breadcrumb>
          <div className="title-main">
            <h1><strong>{t('ABOUT US')}</strong> </h1>
          </div>
          <div className="content-detail">
            <h2 className="center-text">
              <span className="heading-text">{t('FACTORY')}</span>
            </h2>
            <p className="center-text">
            {t('The factory covers an area of ​​6000 square meters and has 120 production lines.')}
            </p>
            <p className="justify-text">
              <img
                alt="Công Ty TNHH Quốc Tế Nice"
                src="https://nicecupvn.com//upload/elfinder/about-factory-01.jpg"
                width="350"
                height="251"
              />
              &nbsp; &nbsp;
              <img
                alt="Công Ty TNHH Quốc Tế Nice"
                src="https://nicecupvn.com//upload/elfinder/about-factory-02.jpg"
                width="350"
              />
              &nbsp; &nbsp;
              <img
                alt="Công Ty TNHH Quốc Tế Nice"
                src="https://nicecupvn.com//upload/elfinder/about-factory-03.jpg"
                width="350"
                height="251"
              />
            </p>
            <h3 className="center-text">
              <span className="heading-text">{t('EXPANDING NEW MARKETS')}</span>
            </h3>
            <p className="justify-text">
            {t('Nice International Company Limited has had certain achievements in the paper packaging printing market and is gradually expanding its business to new markets.')}
            </p>
            <p className="justify-text">
            {t('We are the ones who understand the strengths and weaknesses of our business. That is why our team always improves and develops every day to enhance our position in the domestic and international markets.')}
            </p>
            <h3 className="center-text">
              <span className="heading-text">{t('IMPLEMENTING NEW BUSINESS PLAN')}</span>
            </h3>
            <p className="justify-text">
            {t('Always shaping up as a sustainable business over the years, we always have new business plans, always consider and expand as well as screen business directions. We especially appreciate the cooperation and contributions of our customers in the past and continue to do so to bring the best packaging solutions and accompany customers anytime, anywhere.')}
            </p>
          </div>
          </div>
          </div>
          </div>
  );
};

export default Introduce;
