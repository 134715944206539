import React, { useEffect } from 'react';
import './aboutUs.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const AboutUs = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
    return (
        <section id="gioithieu" className="about-section">
            <div className="container maxwidth">
                <div className="row">
                    {/* Text Section */}
                    <div className="col-md-6 col-sm-5" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="1000">
                        <div className="info-about">
                            <div className="title-about">
                                <span><strong>{t('ABOUT US')}</strong></span>
                            </div>
                            <div className="desc-about">
                                <p>
                                    <span style={{ fontSize: '26px' }}>
                                        <span style={{ color: '#65b741' }}>
                                            <strong>{t('NICE INTERNATIONAL COMPANY LIMITED')}</strong>
                                        </span>
                                    </span>
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                {t('As a company specializing in the production and trading of paper and plastic packaging. Product packaging is the core and purpose of Nice International Company, we produce and trade food packaging from paper and plastic materials. Along with that, we combine the research and production of automation equipment and the management of production research into a unified entity. We will strive to become one of the leading companies in the production and supply of food packaging.')}
                                </p>
                            </div>
                            <div className="link-about">
                                <a href="/Introduce" className="link-more">{t('See more')}</a>
                            </div>
                        </div>
                    </div>

                    {/* Image Section */}
                    <div className="col-md-6 col-sm-7" data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-duration="1000">
                        <div className="about-img">
                            <div className="about-flexbox">
                                <figure className="about-first">
                                    <img src="https://nicecupvn.com/thumbs/320x390x1/upload/news/team-03-2737.jpg" alt="VỀ CHÚNG TÔI" onError={(e) => { e.target.src='thumbs/320x390x2/assets/images/noimage.png'; }} />
                                </figure>
                                <figure className="about-last">
                                    <img src="https://nicecupvn.com/thumbs/320x390x1/upload/news/z52345439263140949bd3d6d4b03b2841edca4a0a915e5-7841.jpg" alt="VỀ CHÚNG TÔI" onError={(e) => { e.target.src='thumbs/320x390x2/assets/images/noimage.png'; }} />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
