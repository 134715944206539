import React, { useEffect } from 'react';
import Slider from "react-slick";
import './NewsSection.css';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import { Card } from 'react-bootstrap';

const newsItems = [
  {
    id: 1,
    title: "Công ty TNHH Khiêm Hòa TP Đông Quản tham hội chợ triển lãm tại Las Vegas (Mỹ)",
    titleen:"Khiem Hoa Co., Ltd. Dong Quan City attended the exhibition in Las Vegas (USA).",
    link: "news/exhibition-las-vegas",
    image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/las-vegas-show-06-1662.jpg",
    description: "Công ty TNHH Khiêm Hòa TP Đông Quản tham hội chợ triển lãm tại Las Vegas (Mỹ)"
  },
  {
    id: 2,
    title: "Công ty TNHH bao bì thực phẩm Khiêm Hòa TP Đông Quản thành lập chi nhánh tại Việt Nam",
    titleen:"Khiem Hoa Food Packaging Co., Ltd. Dongguan City established a branch in Vietnam",
    link: "news/establishe-a-branch",
    image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/vietnam-branch-7683.jpg",
    description: "Công ty TNHH bao bì thực phẩm Khiêm Hòa TP Đông Quản thành lập chi nhánh tại Việt Nam"
  },
  {
    id: 3,
    title: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice",
    titleen: "Annual meeting of Khiem Hoa and Nice company",
    link: "news/annual-meeting",
    image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/web-nice-2381.jpg",
    description: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice"
  }
];

const NewsSection = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section id="news" className='partners-carousel'>
      <div className="maxwidth aos-init aos-animate" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="800">
        <div className="title-main">
          <h2>{t('FEATURED NEWS')}</h2>
        </div>
        
          <Slider {...settings} className="owl-carousel owl-theme">
            {newsItems.map(item => (
             
              <a href={item.link} className="text-decoration-none w-clear" title={item.title}>
                <Card className="h-100">
                  <div className="image-container">
                    <Card.Img
                      onError={(e) => e.currentTarget.src = 'https://nicecupvn.com/thumbs/530x560x2/assets/images/noimage.png'}
                      src={item.image}
                      alt={item.title}
                    />
                      <Card.Body>
                      <Card.Title className="overlay-title single-line-ellipsis" >
                        {(currentLanguage === "en") ? item.titleen : item.title}
                      </Card.Title>
                      </Card.Body>
                  </div>
                </Card>
              </a>
            
            ))}
          </Slider>
        
        
      </div>
    </section>
  );
};
const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-next" onClick={onClick}></div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-prev" onClick={onClick}></div>;
};

export default NewsSection;
