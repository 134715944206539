import React, { useState, useEffect } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../api/googleApi';
import './banner.css'

function Banner() {  
    const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        const imageRefs = [
            'banner/2-85551.png',
            'banner/bia-4487.jpg',
            'banner/l-2006.jpg',
            'banner/nice-9522.png'
        ];

        const fetchImageUrls = async () => {
            const urls = await Promise.all(
                imageRefs.map(imageRef => {
                    const storageRef = ref(storage, imageRef);
                    return getDownloadURL(storageRef);
                })
            );
            setImageUrls(urls);
        };

        fetchImageUrls().catch(error => {
            console.error('Error fetching image URLs:', error);
        });
    }, []);

  return (
    <Container fluid className="carousel-container">
      <Carousel slide='true' fade='true' >
        {imageUrls.map((url, index) => (
            <Carousel.Item key={index}>
                <a href="https://book2.bigwindvi.com/2001/07qh3/index.html" target="_blank" rel="noopener noreferrer">
                <img src={url} alt={`Slide ${index + 1}`}  /></a>
                
            </Carousel.Item>
        ))}
    </Carousel>
    </Container>
    
  );
}

export default Banner;
