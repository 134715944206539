import React, { useEffect } from 'react';
import './CompanyIntroduction.css';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { FaCalendarWeek } from 'react-icons/fa6';

const CompanyIntroduction = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
      }, []);
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    document.title= (currentLanguage === "en" )? "Company introduction":"Giới thiệu công ty";

    return (
        <div id="main" className="wrap-main">
            <div className="maxwidth">
                <div className="content-main w-clear">
                <Breadcrumb >
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/CompanyIntroduction" active>
         {t('Company introduction')}
        </Breadcrumb.Item>
      </Breadcrumb>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-main">
                                <h1><strong>{t('COMPANY INTRODUCTION')}</strong></h1>
                            </div>
                            <div className="time-main">
                                <i className="fas fa-calendar-week"></i>
                                <span><FaCalendarWeek/> {t('Posted date: 01/23/2024 09:19 PM')}</span>
                            </div>
                            <div className="content-detail">
                                <p style={{ textAlign: 'justify' }}>
                                    <span style={{ lineHeight: 1.5, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '16px' }}>
                                    {t('Nice International Co., Ltd. was established in 2018. Up to now, our company has been growing and becoming more and more popular with customers. Nice International Co., Ltd. is a branch of Khiem Hoa Food Packaging Company, a foreign-invested company specializing in providing high-end packaging products for coffee chains and restaurant chains of domestic and foreign brands. Paper packaging products, safe and good quality plastic packaging. Regarding Nice products, there are paper products such as: paper cups, paper bowls, paper boxes, paper bags, paper towels, plastic cups, plastic boxes, plastic lids, aluminum utensils, biodegradable PLA products and accompanying products.')}
                                    </span>
                                </p>
                                <p style={{ textAlign: 'center' }}>
                                    <img alt="Nice International LLC" height="750" src="https://nicecupvn.com//upload/elfinder/z5209388598076_28bea868d0a6f30f3e06d1e8d8b30efb.jpg" width="1000" />
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    <span style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: 1.5 }}>
                                        <strong>{t('The mission')}</strong>{t(' of Nice International Co., Ltd. in the production of paper cups, paper bowls, paper boxes... is to create high-quality, environmentally friendly products and meet the diverse needs of customers. We are committed to providing a variety of paper cup packaging solutions, from paper cups, paper bowls, paper boxes for hot and cold drinks, ensuring safety and convenience for users.  We not only focus on providing quality products but also put environmental protection first. By using recycled materials and advanced production processes, we aim to minimize negative impacts on the environment and the community.')} 
                                    </span>
                                </p>
                                <p style={{ textAlign: 'center' }}>
                                    <img alt="Nice International LLC" height="706" src="https://nicecupvn.com//upload/elfinder/company-profile-03.jpg" width="1000" />
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    <span style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif', lineHeight: 1.5 }}>
                                        <strong>{t('Nice International Co., Ltd.\'s strategic vision')}</strong>{t(' in the field of food packaging paper cups focuses on leveraging new automation technologies to optimize production processes and improve product quality. We understand that customer needs and expectations are changing, from environmental concerns to convenience and food safety requirements. We are committed to investing in product research and development, improving production processes, and creating customized services to meet the diverse needs of our customers. We will focus on pursuing the following: expanding export markets, developing environmentally friendly products, and strengthening partnerships with major food enterprises. At the same time, we will consider internal and external factors such as fluctuations in raw material prices and changes in regulations, and propose necessary actions to prepare for the future.  In the near future, the company is striving to complete the goal of becoming one of the leading companies in the service industry  of providing paper cups, paper bowls...')}
                                    </span>
                                </p>
                                <p style={{ textAlign: 'center' }}>
                                    <img alt="sample" height="750" src="https://nicecupvn.com//upload/elfinder/z5210273652585_ddf75a68863649202c52adea81ecc6da.jpg" width="1000" />
                                </p>
                                <p style={{ textAlign: 'center' }}>
                                    <img alt="Nice International LLC" height="750" src="https://nicecupvn.com//upload/elfinder/z5210272572306_f8fa35f64c7ebac47a0b07f6b64d980b.jpg" width="1000" />
                                </p>
                            </div>
                            </div>
                            </div>
                            </div>
                </div>
            </div>
        
    );
};

export default CompanyIntroduction;
