import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Partner.css"; // Custom styles
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const Partner = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4, // Number of images to show at once
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
 

  };

  const partners=[
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-03-31971.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z5232029531544adb67fb30d8aca4748b70f8819f2e34f-23740.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-06-76170.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z5232029515621d379a784ae311555b5025c835ca35a18-2961.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z5232045317964d92181d1d0a956a3de8412323d7533b5-69790.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320462268462e0f2febdbbef0a5bdb3533f6d0ed5be-95770.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/bfp-5659.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-04-77080.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z5232029524117503812985cc48e9fc8048dde8c9173e1-2180.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320295310429eaa9e89d5d2f86507c83de48769614e-47830.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/skin-85480.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z523202953525980f87d8123a533bdfeb7d0fcd38196b1-60980.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z5232029509472e35dad9b94eeb3af6115343efa9640cd-6907.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z523204669576985ed91ceff0b043d99a5ee1edd68bab1-93050.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/summer-92251.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z523202952378592e8ee1e16ff0a150644e89cb74b9b93-2316.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320295182326e877aca0ef6f9ec98a81aa1bad78410-9021.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320466861247feaabc36917a1333d253863bff50aea-81960.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-09-55981.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-02-19480.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320295367730cc1c153030ee6222c6730f45a7020d8-30210.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-08-70520.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-01-67340.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-07-38021.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320295082627a29b602c041a7c9efa2b10c61f7ae4e-7100.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z52320295280438c34c368310975283e58b0a930d4bbd7-15910.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/index-partner-05-47221.jpg",
    "https://nicecupvn.com/thumbs/210x135x2/upload/photo/z523202950529908406b072a0b936145cfac939a7074bb-8878.jpg"
  ]

  return (
    <section className="partners-carousel">
      <h2><strong>{t('PARTNER')}</strong></h2>
      <Slider {...settings}>
       {partners.map((partner)=>(
        <div className="partner-slide">
        <img src={partner} alt="Partner 4" />
      </div>
       ))}
        
      </Slider>
    </section>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-next" onClick={onClick}></div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-prev" onClick={onClick}></div>;
};

export default Partner;
