import React, { useEffect } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FaCalendarWeek,  } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";

const PlasticProductionProcess = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main">
      <Container className="maxwidth">
      <div >
        <div className="content-main w-clear">
          <Row>
            <Col md={12}>
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Technology" >
        {t('Technology')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item active >{t('Plastic product manufacturing process')}</Breadcrumb.Item>
      </Breadcrumb>
              <h1 className="title-main"><strong>{t('PLASTIC PRODUCT MANUFACTURING PROCESS')}</strong></h1>
              <div className="time-main">
                <FaCalendarWeek />
                <span> {t('Posted date')}: 23/01/2024 07:08 PM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" id="toc-content">
                  <p style={{ fontSize: '16px', fontFamily: 'Arial,Helvetica,sans-serif' }}>
                  {t('The company often uses modern machinery to optimize the production process, from the production of plastic cups and packaging bags. This technology helps increase efficiency and reduce waste')}.
                  </p>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      alt="Công Ty TNHH Quốc Tế Nice"
                      height="846"
                      src="https://nicecupvn.com//upload/elfinder/z5221893583429_343895dc14ba1ebb6e8eb3c4bcc7a312.jpg"
                      width="1080"
                    />
                  </div>
                </div>
                
                <div className="pagination-home"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
    </div>
    
  );
};

export default PlasticProductionProcess;
