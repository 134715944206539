import React from 'react';

const PgFOF = () => {
    return (
        <div>
            404 Not Found
        </div>
    );
};

export default PgFOF;