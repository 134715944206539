import React from 'react';
import './homePage.css' ;
import { Container } from 'react-bootstrap';
import Banner from './components/banner/banner';
import IndustryList from './components/Industrylist/Industrylist';
import AboutUs from './components/aboutUs/aboutUs';
import BestSellingProducts from './components/BestSellingProduct/BestSellingProduct';
import WhyChooseUs from './components/WhyChooseUs/WhyChooseUs';
import Partner from './components/Partner/Partner';
import Statistics from './components/Statistics/Statistics';
import NewsSection from './components/NewsSection/NewsSection';
import NewProduct from './components/NewProduct/NewProduct';
import NewsletterForm from '../components/NewsletterForm/NewsletterForm';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    
    return (
        <Container fluid>
            <Helmet>
            <title>Công Ty TNHH Quốc Tế Nice | Ly, chai, tô, muỗng, ống hút dùng 1 lần</title>
            <meta name="description" content="Công Ty TNHH Quốc Tế Nice - Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam."/>
            <meta name="keywords" content="Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content="Công Ty TNHH Quốc Tế Nice | Ly, chai, tô, muỗng, ống hút dùng 1 lần"></meta>
            <meta property="og:description" content="Công Ty TNHH Quốc Tế Nice - Chuyên sản xuất ly giấy, ly nhựa, tô giấy, hộp giấy, chai nhựa, ống hút, muỗng nhựa... Uy tín, chất lượng hàng đầu Việt Nam."></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
            </Helmet>
            <Banner/>
            <IndustryList/>
            
            <BestSellingProducts/>
            <NewProduct/>
            <AboutUs/>
            <WhyChooseUs/>
            <NewsSection/>
            <Statistics/>
            <Partner/>
            {/* <NewsletterForm isshow={true}/> */}

        </Container>
    );
};

export default HomePage;