import React, { useEffect } from 'react';
import { Container, Row, Col,   Breadcrumb } from 'react-bootstrap';
import { FaCalendarWeek } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
const PurchasePaymentMethod = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
  return (
    <Container  className="wrap-main">
      <div >
        <div className="content-main w-clear">
          <Row>
          <div >
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Service" >
        {t('Service')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item  active >
        {t('Purchase and payment methods')} 
        </Breadcrumb.Item>
      </Breadcrumb>
            </div>
            <Col md={12}>
              <h1 className="title-main"><strong>{t('PURCHASE AND PAYMENT METHODS')}</strong></h1>
              <div className="time-main">
                <FaCalendarWeek />
                <span> {t('Posted date')}: 23/01/2024 07:09 PM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" >
                  <p >
                    <strong>{t('Order')}: </strong>{t('Contact sales staff directly for quick and timely advice and quotation')}.<br />
                    <strong>{t('Payment')}: </strong>{t('Customers deposit 50% of the total order, the remaining amount is paid when the goods are shipped')}.
                  </p>
                </div>
            
            
              </div>
              {/* <div className="share othernews">
                <b>Bài viết khác:</b>
                <ListGroup as="ul" className="list-news-other">
                  
                </ListGroup>
                <div className="pagination-home"></div>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default PurchasePaymentMethod;
