import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ZaloButton.css'
import { gtag_report_conversion } from '../gtag';

const ZaloButton = () => {
  return (
    <a
      className="btn-zalo btn-frame text-decoration-none hidden-xs"
      href="https://zalo.me/0931327609"
      onClick={() => gtag_report_conversion('https://zalo.me/0931327609')}
    >
      <div className="animated infinite zoomIn kenit-alo-circle" style={{border: '2px solid #0068FF'}}></div>
      <div className="animated infinite pulse kenit-alo-circle-fill" ></div>
      <div style={{ width:'50px',padding:'5px' , height:'50px'}}>
      <img style={{width:'35px', height:'35px',margin:"7px"  }} src="https://nicecupvn.com/assets/images/zl.png" alt="Zalo" />
      </div>
      
    </a>
  );
};

export default ZaloButton;
