import React from 'react';
import './CallButton.css'; // Import CSS cho styling
import { FaPhoneAlt } from "react-icons/fa"; // Import icon từ react-icons
import { gtag_report_conversion } from '../gtag';


const CallNowButton = () => {
  return (
    
    <div className="call-now-button hidden-xs">
      
      <p class="call-text" style={{display:'table'}}>
        <a onClick={() => gtag_report_conversion('tel:0931 327 609')} href="tel:0931 327 609" title="0931 327 609" style={{textDecoration:'none' , color:'#fff', paddingLeft:'10px'}}>
       <strong>0931 327 609</strong>  
           </a>
           </p>
           <div className='icon'>
           <a href="tel:0931 327 609" title="0931 327 609">
          <div className="animated infinite zoomIn kenit-alo-circle"></div>
          <div className="animated infinite pulse kenit-alo-circle-fill "></div>
          <div className="quick-alo-ph-btn-icon quick-alo-phone-img-circle">
            <FaPhoneAlt /> 
          </div>

        </a>
           </div>
        
        
    </div>
  );
};

export default CallNowButton;
