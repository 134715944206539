import React, { useEffect } from 'react';
import './BestSellingProduct.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const BestSellingProducts = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
 
  };

  const products = [
    {
      id: 1,
      name: 'Hộp thuyền - Hộp gà rán',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/ship-box-01-6518.jpg',
      nameen:'Boat box - fried chicken box',
      href:'/Products/4/42',
    },
    {
      id: 2,
      name: 'Giấy ăn - Khăn giấy rút - khăn giấy cầm tay',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/z5228709404458315fc34898c2e72d9045a8d2f1db1a16-2071.jpg',
      nameen:'Tissue paper - Tissue paper - hand towel',
      href:'',
    },
    {
      id: 3,
      name: 'Tô nhựa tròn',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/plastic-bowls-two-01-3330.jpg',
      nameen:'Round plastic bowl',
      href:'/Products/3/27',
    },
    {
      id: 4,
      name: 'Hộp đựng khoai tây chiên, gà viên chiên',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/french-fries-box-01-2699.jpg',
      nameen:'Box of French fries, fried chicken nuggets',
      href:'/Products/4/34',
    },
    {
      id: 5,
      name: 'Ly giấy trung không',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/insulating-paper-cups-03-4881.jpg',
      nameen:'Medium paper cup',
      href:'/Products/2/19',
    },
    {
      id: 6,
      name: 'Ly giấy hình vuông',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/z5218805832619d24bcde7d936fa9471ab8f372911d24b-2301.jpg',
      nameen:'Square paper cup',
      href:'/Products/2/05',
    },
    {
      id: 7,
      name: 'Hộp hamburger',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/1-5108.jpg',
      nameen:'Hamburger Box',
      href:'/Products/4/45',
    },
    {
      id: 8,
      name: 'Hộp giấy có nắp gập',
      image: 'https://nicecupvn.com/watermark/product/540x470x1/upload/product/z522932453208712a3c92d74a5d16d00644aebaa790225-7488.jpg',
      nameen:'Paper box with folding lid',
      href:'/Products/4/36',
    },
  ];

  return (
    <section className="partners-carousel">
      <h2><strong>{t('BEST SELLING PRODUCTS')}</strong></h2>
      <Slider {...settings}>
        {products.map((product) => (
          <a href={product.href} style={{textDecoration: 'none'}}>
            <Card >
          <Card.Img src={product.image} ></Card.Img>
          <Card.Body>
          <Card.Title className="single-line-ellipsis">{(currentLanguage === "en" )? product.nameen:product.name}</Card.Title>
          <Card.Text style={{color:'red'}}>{(currentLanguage === "en" )? "Contact" :"Liên hệ"}</Card.Text>
          </Card.Body>
         
        </Card></a>
          
        ))}
      </Slider>
    </section>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-next" onClick={onClick}></div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-prev" onClick={onClick}></div>;
};

export default BestSellingProducts;
